import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import { PointService } from '../../service/service';

// const URL = '/api/';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-upload.component.html'
})
export class UploaderComponent implements OnInit {
  ngOnInit () {}
  public uploading: boolean = false;
  @Output() onUpload: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onDurationReady: EventEmitter<any> = new EventEmitter();
  @Input() type: string;
  @Input() url: string;
  public duration: number;

  @ViewChild('ng2FileSelect') ng2FileSelect;
  @ViewChild('audioComponent') audioComponent: ElementRef;
  public ngOnChanges(changes: SimpleChanges) { }

  constructor(private service: PointService) {
    var that = this;
    this.uploader.onSuccessItem = (item, resp, status, headers) => {
      that.uploader.queue.splice(0, 1);
      that.url = resp;
      that.uploading = false;

      let response = {
        url: resp
      };

      if (that.type === "audio") {
        response['duration'] = that.duration;
      }

      that.onUpload.emit(response);
    }
  }
  public uploader: FileUploader = new FileUploader({ url: this.service.URLs.fileUpload, headers: this.headers });
  /*
    public get item() {
      return this.uploader.queue;
    }
  */
  public get headers() {
    let ret = [];
    let json = this.service.getHeaders().toJSON();
    for (let key in json) {
      ret.push({ name: key, value: json[key][0] });
    }
    return ret;
  }
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  public onFileSelected(file) {
    this.uploading = true;
    this.uploader.queue[0].upload();
    if (this.type === 'audio') {
      this.calculateDuration(file[0]);
    }
  }

  public onDeleteFile() {
    this.url = undefined;
    this.onDelete.emit();
    /*let that = this;
    let serviceUrl = this.service.URLs.admin + this.service.endpoints.file;
    this.service.httpDelete(serviceUrl, { url: this.url })
      .then(response => {
        that.url = undefined;
      })
      .catch(error => { })*/
  }

  calculateDuration(file) {
    console.log(file);
    var that = this;
    var audio = document.createElement('audio');
    audio.preload = 'metadata';

    audio.onloadedmetadata = function () {
      window.URL.revokeObjectURL(audio.src);
      var duration = audio.duration;
      file.duration = duration;
      that.duration = duration;

    }

    audio.src = URL.createObjectURL(file);
  }

}