import { Component, NgModule, ViewContainerRef } from '@angular/core';
//import { BrowserModule } from '@angular/platform-browser';
//import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
//import { ButtonModule, PasswordModule, InputTextModule } from 'primeng/primeng';
//import { ToastsManager, Toast } from 'ng2-toastr/ng2-toastr';
import { PointService } from '../../service/service';

@Component({
    selector: 'login-app',
    templateUrl: './login.html',
})
export class LoginComponent {

    public email: string;
    public password: string;
    public isRequestRunning: boolean = false;

    constructor(private service: PointService) {
        document.getElementById('nb-global-spinner').style.display = 'none';
    }

    public keyDownFunction($event) {
        if ($event.keyCode == 13) {
            this.login();
        }
    }

    public login() {
        if (!this.isRequestRunning) {
            this.isRequestRunning = true;

            this.service.httpPost(this.service.URLs.login, { email: this.email, password: this.password })
                .then(response => {
                    localStorage.setItem("authenticated", response.token);
                    (window as any).location = "/";
                }).catch(error => {
                    this.isRequestRunning = false;
                    console.log("error var gardaşım", error);
                });
        }
    }
}

