import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
//import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from "../../environments/environment";
import { Utils } from "../helpers/utils";
//import { FileUploader } from 'ng2-file-upload';
import { filter } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { error } from 'util';
import { reject } from 'q';

const version = "v3";
const domain = environment.serviceUrl + "/api/" + version + "/en/";
const adminURL = environment.serviceUrl + "/api/" + version + "/en/admin/";
const upload_url = adminURL;


export const endpoints = {
    content: "content",
    countries: 'countries',
    campaigns: 'campaigns',
    cities: 'cities',
    file: 'file',
    tags: 'tags',
    guides: 'guides',
    promos: 'promos',
    users: 'users',
    bulkUsers: 'bulk/users',
    ratings: 'users_ratings',
    approvalRating: 'ratings/approval/',
    disapprovedRatings: 'ratings/unapproved',
    listenReport: 'listenReport',
    homeCategories: 'home_categories',
    homePromotions: 'home_promotions',
    homeAffiliations: 'home_affiliations',
    dailyStories: 'dailyStories',
    search: 'search',
    config: 'config',
}

export const urls = {
    base: domain,
    login: domain + "auth/signin",
    admin: adminURL,
    content: adminURL + endpoints.content,
    fileUpload: adminURL + endpoints.file + '/upload'
}


export const NotAuthenticated = "NotAuthenticated";

@Injectable()
export class PointService {
    private username: string;
    private password: string;
    public UPLOAD_URL = upload_url;
    public endpoints = endpoints;
    public URLs = urls;
    constructor(private http: Http, public utils: Utils) { }

    private arrayToString(requiredFields) {
        let str = ""
        if (requiredFields instanceof Array) {
            for (let e of requiredFields) {
                str += e + ",";
            }
            str = str.substring(0, str.length - 1)
        }
        return str;
    }

    public getHeaders(requiredFields?: string[], additionalHeader?: { key: string, value: string }[]): Headers {
        let token = localStorage.getItem("authenticated");
        let headers = new Headers();
        if (requiredFields instanceof Array) {
            headers.append('x-fields', this.arrayToString(requiredFields));
        }
        headers.append('x-access-token', token);
        headers.append('x-secret', '8661404a-3005-4970-a50f-99a0e615f102');
        if (additionalHeader) {
            for (let add of additionalHeader) {
                headers.append(add.key, add.value);
            }
        }
        return headers;
    }

    public httpPost(url, body): Promise<any> {
        this.utils.showLoading();
        return this.http.post(url, body, { headers: this.getHeaders() }).toPromise()
            .then(this.returnResponse).catch(this.handleError);
    }

    public httpPut(url, body): Promise<any> {
        this.utils.showLoading();
        return this.http.put(url, body, { headers: this.getHeaders() }).toPromise()
            .then(this.returnResponse).catch(this.handleError);
    }

    public httpDelete(url, body?): Promise<any> {
        this.utils.showLoading();
        return this.http.delete(url, { headers: this.getHeaders(), body: body, }).toPromise()
            .then(this.returnResponse).catch(this.handleError);
    }

    public httpGet(url: string, queryStringObj?: any, requiredFields?: any): Promise<any> {
        this.utils.showLoading();
        let queryString = this.objectToQuerystring(queryStringObj);
        return this.http.get(url + queryString, { headers: this.getHeaders(requiredFields) }).toPromise()
            .then(this.returnResponse).catch(this.handleError);
    }

    public returnResponse(res) {
        if (res.status === 200) {
            let body;
            try {
                body = JSON.parse(res._body);
            } catch (error) {
                body = res._body;
            }
            // hide loading
            document.getElementById("body").classList.remove("loading-active");

            return body.data || body || {};
        } else {
            return reject("Something bad happened; please try again later.");
        }
    }

    private handleError(error) {
        // hide loading
        document.getElementById("body").classList.remove("loading-active");
        console.log("error handler", error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            if (error.status === 401) {
                localStorage.setItem('authenticated', '');
                (window as any).location = "/#/login";
            }
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        let errorBody
        try {
            errorBody = JSON.parse(error._body);
        } catch (error) {
            errorBody = error._body;
        }
        // return an observable with a user-facing error message
        return reject(errorBody.message || "Something bad happened; please try again later.");
    };

    private objectToQuerystring(obj) {
        if (obj) {
            let queryString = '?';
            for (let key of Object.keys(obj)) {
                if (obj[key] !== '') {
                    if (queryString !== '?') queryString += '&';
                    queryString += key + '=' + encodeURIComponent(obj[key]);
                }
            }
            return queryString === '?' ? '' : queryString;
        } else { return ""; }
    }

    public callHttpHead(url) {
        return this.http.head(url, {});
        //  .map(resp => { return resp.headers }).catch(this.handleError);
    }

    public isAuthenticated() {
        let token = localStorage.getItem("authenticated");
        console.log("1", token);
        return Boolean(token);
    }
    /* public callHttpPostWithoutAuth(url: string, body: any, extractData?: (res: Response) => any): Observable<any> {
         return this.http.post(url, body, { headers: this.getHeaders() })
             .map(extractData ? extractData : this.extractData)
         // .catch(this.handleError);
     }*/
    /* public callHttpGetWithoutAuth(url: string, headers?: any, extractData?: (res: Response) => any): Observable<any> {
         return this.http.get(url, { headers: headers })
             .map(extractData ? extractData : this.extractData)
             .catch(this.handleError);
     }
     public callHttpPostWithoutAuth(url: string, body: any, extractData?: (res: Response) => any): Observable<any> {
         return this.http.post(url, body, { headers: this.getHeaders() })
             .map(extractData ? extractData : this.extractData)
             .catch(this.handleError);
     }
     public callHttpGet(url: string, requiredFields: string[], extractData?: (res: Response) => any, additionalHeader?: { key: string, value: string }[]): Observable<any> {
         if (!this.isAuthenticated()) {
             return Observable.fromPromise(new Promise((resolve, reject) => reject(NotAuthenticated)));
         }
         let headers = this.getHeaders(requiredFields, additionalHeader);
         return this.http.get(url, { headers: headers })
             .map(extractData ? extractData : this.extractData)
             .catch(this.handleError);
     }
 
     public callHttpPost(url: string, body: any, extractData?: (res: Response) => any): Observable<any> {
         if (!this.isAuthenticated()) {
             return Observable.fromPromise(new Promise((resolve, reject) => reject(NotAuthenticated)));
         }
         return this.http.post(url, body, { headers: this.getHeaders() })
             .map(extractData ? extractData : this.extractData)
             .catch(this.handleError);
     }
 
     public callHttpPut(url: string, body: string, extractData?: (res: Response) => any): Observable<any> {
         if (!this.isAuthenticated()) {
             return Observable.fromPromise(new Promise((resolve, reject) => reject(NotAuthenticated)));
         }
         return this.http.put(url, body, { headers: this.getHeaders() })
             .map(extractData ? extractData : this.extractData)
             .catch(this.handleError);
     }
 
     public callHttpDelete(url: string, extractData?: (res: Response) => any): Observable<any> {
         if (!this.isAuthenticated()) {
             return Observable.fromPromise(new Promise((resolve, reject) => reject(NotAuthenticated)));
         }
         return this.http.delete(url, { headers: this.getHeaders() })
             .map(extractData ? extractData : this.extractData)
             .catch(this.handleError);
     }
 
     private toStringFromArray(requiredFields) {
         let str = ""
         if (requiredFields instanceof Array) {
             for (let e of requiredFields) {
                 str += e + ",";
             }
             str = str.substring(0, str.length - 1)
         }
         return str;
     }
 
     private extractData(res) {
         let body = res.json();
         return body.data;
     }
 
     public getHeaders(requiredFields?: string[], additionalHeader?: { key: string, value: string }[]): Headers {
         let token = localStorage.getItem("authenticated");
         let headers = new Headers();
         if (requiredFields instanceof Array) {
             headers.append('x-fields', this.toStringFromArray(requiredFields));
         }
         headers.append('x-access-token', token);
         headers.append('x-secret', '8661404a-3005-4970-a50f-99a0e615f102');
         if (additionalHeader) {
             for (let add of additionalHeader) {
                 headers.append(add.key, add.value);
             }
         }
         return headers;
     }
 
     private handleError(error: Response | any) {
         console.log(error);
         // In a real world app, we might use a remote logging infrastructure
         let errMsg: string;
         if (error instanceof Response) {
             const body = error.json() || '';
             const err = body.error || body;
             errMsg = error.status === 403 || error.status === 401 ? NotAuthenticated : err;
         } else {
             let msg = error.message ? error.message : error.toString();
             errMsg.includes("Unauthorized") ? NotAuthenticated : msg;
         }
         //console.error(errMsg);
         return Observable.throw(errMsg);
     }*/

}