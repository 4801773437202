import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { NbGlobalLogicalPosition, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { DOCUMENT } from '@angular/common';
import * as dateformat from "dateformat";

@Injectable()
export class Utils {
    private renderer: Renderer2;
    public urlRegex = /(https?:\/\/piri([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/g;
    // /(https?:\/\/[^\s]+piri)/g;
    Math: any;
    constructor(@Inject(DOCUMENT) private document: Document, private toastrService: NbToastrService, private rendererFactory: RendererFactory2, ) {
        // this.renderer = rendererFactory.createRenderer(null, null);
        // this.Math = Math;
    }

    public showLoading() {
        this.document.body.classList.add('loading-active');
        return;
    }

    public hideLoading() {
        this.document.body.classList.remove('loading-active');
        return;
    }
    /*
        public reloadPage() {
            window.location.reload();
        }
    */

    notificationStatuses = {
        'success': NbToastStatus.SUCCESS,
        'error': NbToastStatus.DANGER,
        'warning': NbToastStatus.WARNING
    };

    config = {
        destroyByClick: true,
        duration: 6000,
        hasIcon: false,
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        preventDuplicates: false,
    };
    public showNotification(type, message) {
        const notificationType: NbToastStatus = this.notificationStatuses[type];
        this.config['status'] = notificationType;
        this.toastrService.show(
            message,
            '',
            this.config);
    }


    public jsonToQueryString(data) {
        var buffer = [];

        // Serialize each key in the object.
        for (var name in data) {
            if (!data.hasOwnProperty(name)) {
                continue;
            }
            if (data[name] != "") {
                var value = data[name];

                buffer.push(
                    encodeURIComponent(name) + "=" + encodeURIComponent((value == null) ? "" : value)
                );
            }
        }

        // Serialize the buffer and clean it up for transportation.
        var source = buffer.join("&").replace(/%20/g, "+");
        return (source);
    }

    public convertTimestampToDate(timestamp) {
        return dateformat(new Date(Number(timestamp) * 1000), "yyyy-mm-dd")
    }

    public convertTimestampToDateTime(timestamp) {
        return dateformat(new Date(Number(timestamp) * 1000), "dd-mm-yyyy HH:MM")
    }


    public convertDateToTimestamp(date) {
        return new Date(date).getTime() / 1000;
    }

    public convertDateFormat(date) {
        return dateformat(date, "yyyy-mm-dd")
    }

    public getCurrentDate() {
        return dateformat(new Date(), "yyyy-mm-dd");
    }

    public getTomorrowsDate() {
        return dateformat(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), "yyyy-mm-dd");
    }

    public getYesterdaysDate() {
        return dateformat(new Date(new Date().getTime() - 24 * 60 * 60 * 1000), "yyyy-mm-dd");
    }

    public bytesToSize(bytes: any) {
        bytes = parseInt(bytes);
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(this.Math.floor(this.Math.log(bytes) / this.Math.log(1024)));
        return this.Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    public convertSecondsToFancyFormat(time) {
        // Hours, minutes and seconds
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = ~~time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    public getCdnUrlsFromString(content) {
        content = this.encodeSpecificCharactersInURL(content);
        return content.match(this.urlRegex);
    }

    // this is hack for some unacceptable cdn paths
    public encodeSpecificCharactersInURL(content) {
        content = content.replace(/\(/gi, "%28");
        content = content.replace(/\)/gi, "%29");
        return content;
    }

    public isObjectFull(obj: Object, params: string[]) {
        for (let i in params) {
            if (obj[params[i]] === null || obj[params[i]] === '' || obj[params[i]] === {}) {
                console.log("false", obj[params[i]]);
                return false;
            } else if (typeof obj[params[i]] === "object" && !Array.isArray(obj[params[i]])) {
                for (let key of Object.keys(obj[params[i]])) {
                    if (obj[params[i]][key] == null || obj[params[i]][key] === '' || obj[params[i]][key] === {}) {
                        console.log("false", obj[params[i]][key]);
                        return false;
                    }
                }
            } else if (Array.isArray(obj[params[i]]) && obj[params[i]].length === 0) {
                return false;
            }
        }

        return true;
    }

    public compareTwoObjects(originalObj, changedObj) {
        let objectKeys = Object.keys(changedObj);
        let resultObj = {};
        for (let key of objectKeys) {
            if ((!originalObj[key] && changedObj[key]) || (JSON.stringify(originalObj[key]) !== JSON.stringify(changedObj[key]))) {
                resultObj[key] = changedObj[key];
            }
        }
        return resultObj;
    }

    public getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * 16)] ;
        }
        // '75' is adding opacity to color;
        return color + '75';
    }

}