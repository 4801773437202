import { CanActivate, ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from "./auth/guard";
import { LoginComponent } from "./auth/login/login";
import { LogoutComponent } from "./auth/logout/logout";

const routes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: '', canActivate: [AuthGuard], loadChildren: 'app/pages/pages.module#PagesModule'
  },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  declarations: [],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
