import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, } from "@angular/router";
import { Observable } from "rxjs/Rx";
import {PointService} from "../service/service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private service :PointService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if(this.service.isAuthenticated()){
     return true; 
    }else{
      this.router.navigate(['/login']);
      return false;
    }
  }
}
